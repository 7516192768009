<template>
  <div class="information compare">

    <b-jumbotron
      v-if="jumbotron"
      class="page-header contained">
        <template #header>{{ translate(jumbotron.header, jumbotron.headerDefault) }}</template>
        <template
          #lead>
          &nbsp;
        </template>
      </b-jumbotron>

    <section class="sections">
      <section class="compare-section-header contained">
        <div>{{ translate(optionHeaders.method, optionHeaders.methodDefault) }}</div>
        <div>{{ translate(optionHeaders.select, optionHeaders.selectDefault) }}</div>
      </section>
      <section
        class="information-section">
        <article
          class="information-article contained"
          :class="{'active': option.selected }"
          v-for="(option, optionIndex) in methods"
          :key="optionIndex"
          @click="methodSelected(option)">
          <div class="information-article-header">
            <div class="label">
              {{ translate(`method-${option.methodId}-contentTitle`, option.contentTitle) }}
            </div>
            <div class="article-header-button-wrapper">
              <b-button
                :disabled="!methodsToCompareByMethodId(option.methodId) && methodsToCompare.length == 3"
                class="round"
                variant="outline-info"
                :pressed="methodsToCompareByMethodId(option.methodId) ? true : false">
                <font-awesome-icon
                  :icon="methodsToCompareByMethodId(option.methodId) ? selectIcon.selectedIcon : selectIcon.unSelectedIcon" />
              </b-button>
            </div>
          </div>
        </article>
      </section>
    </section>
    <section
      class="contained action-button-container"
      v-if="actionButton">
      <b-button
          :to="actionButton.link"
          block
          :disabled="!methodsToCompare.length"
          variant="secondary"
          class="leading">
          <font-awesome-icon :icon="actionButton.icon"/>
          <span class="button-text">{{ translate(actionButton.text, actionButton.text) }}</span>
      </b-button>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CompareSelect',

  data: () => ({
    pageTitle: 'methods',
    jumbotron: {
      header: 'selectMethodTitle',
      headerDefault: 'Please select up to three methods to compare',
      lead: 'selectMethodDescription',
      leadDefault: '&nbsp;',
    },
    optionHeaders: {
      method: 'method',
      methodDefault: 'Method',
      select: 'selectMethodSelect',
      selectDefault: 'Select',
    },
    actionButton: {
      text: 'continue',
      link: 'methods-comparison-table',
      icon: ['fas', 'arrow-right'],
    },
    selectIcon: {
      unSelectedIcon: ['fas', 'circle'],
      selectedIcon: ['fas', 'check'],
    },
  }),

  computed: {
    ...mapGetters('method', [
      'methods',
      'methodsToCompare',
      'methodsToCompareByMethodId',
    ]),
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  methods: {
    ...mapActions('method', [
      'addMethodToCompare',
    ]),
    async methodSelected(method) {
      if (this.methodsToCompareByMethodId(method.methodId) || this.methodsToCompare.length < 3) {
        await this.addMethodToCompare(method);
      }
    },
    continueToComparison() {
      this.$router.push('/compare-methods-table');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base/_mixins.scss";

.action-button-container {
  @include media(tablet-desktop) {
    a {
      width: fit-content;
      min-width: 215px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
